.header {
  height: 64px;
}
.spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
